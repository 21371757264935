<script setup lang="ts"></script>
<template>
  <div
    class="relative w-full flex flex-col min-h-screen overflow-hidden antialiased transition duration-150  visual-effects dark:bg-gradient-to-b bg-[#fff] dark:from-[#333] dark:to-totem-pole-900"
  >
    <div class="relative w-full  px-0 pt-0 mx-auto lg:px-0">
      <!-- class="relative w-full max-w-6xl px-6 pt-6 mx-auto lg:px-16" -->
      <NavigationTheHeader />
    </div>

    <div class="relative text-gray-900 w-full">
      <slot />
    </div>

    <div class=" w-full px-0 pt-6 pb-12 mx-auto lg:px-0">
      <!-- class="relative w-full max-w-6xl px-2 pt-6 pb-12 mx-auto lg:px-16" -->
      <NavigationTheFooter />
    </div>

    <div class="fixed z-10 bottom-4 left-4">
      <!-- <ScrollTop /> -->
    </div>
  </div>
</template>
